import { APIChannel } from 'app/api/routes/channels';
import appConfig from 'app/config/appConfig';
import { CHANNEL_THEME, Theme } from 'app/constants/theme';
import { TextInput } from 'app/design/input-fields';
import useProfile from 'app/hooks/useProfile';
import { useSafeArea } from 'app/provider/safe-area/use-safe-area';
import React from 'react';
import { KeyboardAvoidingView, Platform, StyleProp, Text, ViewStyle } from 'react-native';
import setDefaultProps from 'react-native-simple-default-props';

if (Platform.OS !== 'web') {
  const channel = (appConfig.mobileChannel ?? '') as APIChannel['key'];
  const theme = (CHANNEL_THEME[channel] ?? CHANNEL_THEME['']) as Theme;
  const customTextProps = {
    style: {
      fontFamily: 'Inter-Medium',
      fontSize: 14 * theme.constants.fontScaleFactor,
      color: theme.colors.neutral8,
    },
  };

  setDefaultProps(Text, customTextProps);
  setDefaultProps(TextInput, {
    style: {
      fontFamily: 'Inter',
      color: theme.colors.neutral8,
    },
  });
}

type LayoutFontGlobalProps = React.PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
}>;

export const LayoutFontGlobal = ({ children, style }: LayoutFontGlobalProps) => {
  const insets = useSafeArea();
  const { theme } = useProfile();

  return (
    <KeyboardAvoidingView
      behavior={Platform.select({ ios: 'padding', android: 'height' })}
      style={[
        {
          flex: 1,
          backgroundColor: theme.colors.neutral1,
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          paddingLeft: insets.left,
          paddingRight: insets.right,
        },
        style,
      ]}
    >
      {Platform.OS === 'web' ? (
        <Text
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'Inter-Medium',
            fontSize: 14 * theme.constants.fontScaleFactor,
            color: theme.colors.neutral8,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </KeyboardAvoidingView>
  );
};
